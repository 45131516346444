const pavFieldsDefault = {
  uuid: '',
  action_id: '',
  possibleRegions: [],
  region: '',
  formRequested: false,
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  signupLink: null,
  submitMethod: 'print_mail', // submitMethod from user
  dataCorrection: false, // true if the user is going back to correct previously entered data on the same action
  isPAV: true,
};

const pavFieldsReducers = (state = pavFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'PAV_UPDATE_FIELDS':
      return { ...state, ...action.pavFields };
    case 'PAV_RESET_FIELDS':
      return { ...pavFieldsDefault };
    case 'PAV_SET_DATA_CORRECTION_FLAG':
      return { ...state, dataCorrection: true };
    case 'PAV_ADD_REGION':
      return { ...state, region: action.region };
    case 'PAV_ADD_SIGNUP_LINK':
      return { ...state, signupLink: action.signupLink };
    case 'PAV_SET_SUBMIT_METHOD':
      return { ...state, submitMethod: action.submitMethod };
    default:
      return state;
  }
};

export default pavFieldsReducers;
