const absenteeFieldsDefault = {
  uuid: '',
  action_id: '',
  possibleRegions: [],
  region: '',
  formRequested: false,
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  isPAV: null,
  ovbmLink: null,
  esignMethod: 'self_print', // esignMethod from Turnout
  initialEsignEligibility: false, // initialEsignEligibility meaning: Some states have esign, but certain regions within that state do not.
  submitMethod: 'print_mail', // submitMethod from user
  dataCorrection: false, // true if the user is going back to correct previously entered data on the same action
  stateFields: {
    loaded: false,
    enabled: false,
    requestedState: '',
    fields: {},
    shownFields: [],
  },

  // Some fields aren't stored on the backend (because they contain sensitive
  // data), so they have to re-submitted with each PATCH.
  patchFields: {},
};

const absenteeFieldsReducers = (state = absenteeFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'ABSENTEE_UPDATE_FIELDS':
      return { ...state, ...action.absenteeFields };
    case 'ABSENTEE_RESET_FIELDS':
      return { ...absenteeFieldsDefault };
    case 'ABSENTEE_ADD_STATE_FIELDS':
      return {
        ...state,
        stateFields: {
          ...state.stateFields,
          fields: action.fields,
          loaded: true,
          enabled: action.enabled,
          signatureStatement: action.signatureStatement,
          requestedState: action.state,
        },
      };
    case 'ABSENTEE_CLEAR_STATE_FIELDS':
      return {
        ...state,
        stateFields: absenteeFieldsDefault.stateFields,
      };
    case 'ABSENTEE_UPDATE_SHOWN_FIELDS':
      if (action.remove) {
        return {
          ...state,
          stateFields: {
            ...state.stateFields,
            shownFields: state.stateFields.shownFields.filter(
              (field) => field !== action.uuid
            ),
          },
        };
      }

      return {
        ...state,
        stateFields: {
          ...state.stateFields,
          shownFields: [...state.stateFields.shownFields, action.uuid],
        },
      };
    case 'ABSENTEE_FORM_REQUESTED':
      return { ...state, formRequested: true };
    case 'ABSENTEE_RESET':
      return {
        ...state,
        uuid: '',
        action_id: '',
        possibleRegions: [],
        region: '',
        formRequested: false,
      };
    case 'ABSENTEE_ADD_REGION':
      return { ...state, region: action.region };
    case 'ABSENTEE_ADD_ESIGN_METHOD':
      return { ...state, esignMethod: action.esignMethod };
    case 'ABSENTEE_INITIAL_ESIGN_ELIGIBILITY':
      return {
        ...state,
        initialEsignEligibility: action.initialEsignEligibility,
      };
    case 'ABSENTEE_UPDATE_PATCH_FIELDS':
      return {
        ...state,
        patchFields: action.fields,
      };
    case 'ABSENTEE_SET_SUBMIT_METHOD':
      return { ...state, submitMethod: action.submitMethod };
    case 'ABSENTEE_ADD_OVBM_LINK':
      return { ...state, ovbmLink: action.ovbmLink };
    case 'ABSENTEE_SET_DATA_CORRECTION_FLAG':
      return { ...state, dataCorrection: true };
    case 'ABSENTEE_CLEAR_DATA_CORRECTION_FLAG':
      return { ...state, dataCorrection: false };
    case 'ABSENTEE_SET_PAV_FLAG':
      return { ...state, isPAV: true };
    default:
      return state;
  }
};

export default absenteeFieldsReducers;
