import React from 'react';
import { navigate } from 'gatsby';
import { globalHistory } from '@gatsbyjs/reach-router';
import queryString from 'query-string';
import ReduxWrapper, { store } from './src/state/ReduxWrapper';
import subscriberThunk from './src/actions/subscriberThunk';
import { scrollParent } from './src/utils/window';
import './src/styles/global.css';

export const onClientEntry = () => {
  /* parse query string on initial load */
  const queryParams = queryString.parse(window.location.search) || {};

  /* store query parameters as window level object */
  if (typeof window !== 'undefined') {
    window.voteamerica = {};
    window.voteamerica.queryParams = queryParams;
  }

  /* dispatch thunk to change subscriber options */
  store.dispatch(subscriberThunk());
};

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  /* eslint-disable no-underscore-dangle */
  globalHistory._onTransitionComplete();

  // From: https://github.com/reach/router/issues/262
  globalHistory.listen(({ action }) => {
    if (action === 'PUSH') {
      scrollParent();
    }
  });
};

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <ReduxWrapper element={element} />;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!location.pathname.endsWith('/')) {
    navigate(`${location.pathname}/`, { replace: true });
  }

  /*
    Any URL beyond the first page of a tool will hide distractions
    e.g. /tools/register/submit-method/
  */
  if (location.pathname.match(/^\/tools\//)) {
    store.dispatch({ type: 'SITECONFIG_HIDE_DISTRACTIONS' });
  } else {
    store.dispatch({ type: 'SITECONFIG_SHOW_DISTRACTIONS' });
  }

  store.dispatch({ type: 'SITECONFIG_SHOW_LOCATE_TEXT' });

  if (prevLocation) {
    // on route change (not initial render), scroll the parent window back to
    // the top if we're in an iframe
    scrollParent();
  }
};
