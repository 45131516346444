import { combineReducers } from 'redux';
import person from './person';
import sourcing from './sourcing';
import absenteeFields from './absenteeFields';
import registerFields from './registerFields';
import verifyFields from './verifyFields';
import leoFields from './leoFields';
import locateFields from './locateFields';
import pledgeFields from './pledgeFields';
import remindFields from './remindFields';
import upcomingFields from './upcomingFields';
import futurevoterFields from './futurevoterFields';
import siteConfig from './siteConfig';
import updateFields from './updateFields';
import pavFields from './pavFields';

export default combineReducers({
  absenteeFields,
  futurevoterFields,
  leoFields,
  locateFields,
  pavFields,
  person,
  pledgeFields,
  registerFields,
  remindFields,
  siteConfig,
  sourcing,
  upcomingFields,
  updateFields,
  verifyFields,
});
